import {Route, Routes} from 'react-router';
import '../stylesheets/App.css';
import Main from './Main';

function App() {
  return (
    <>
      <Routes>
        <Route path='*' exact element={<Main />}/>
      </Routes>
    </>
  );
}

export default App;
