import {Component} from "react";
import {FaBlog, FaGithub, FaInstagram, FaLinkedin, FaSoundcloud, FaTwitter} from "react-icons/fa";

function currentYear() {

    return new Date().getFullYear()
}

class Main extends Component {

    render() {

        const {children, contentClassName = ''} = this.props

        return <>
            <div className='container h-100'>
                <div className={`content pt-3 ${contentClassName}`}>
                    <div className={'col-12 d-flex justify-content-center mt-3'}>
                        <h1 className={'name'}>s</h1>
                    </div>
                    <div className='Footer mt-md-3'>
                        <div className='d-flex w-100 justify-content-center mb-4'>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://blog.developersunesis.com' target='_blank'
                                   rel='noreferrer'>
                                    <FaBlog className='FooterFa'/>
                                </a>
                            </span>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://www.linkedin.com/in/developersunesis'
                                   target='_blank'
                                   rel='noreferrer'>
                                    <FaLinkedin className='FooterFa'/>
                                </a>
                            </span>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://github.com/developersunesis' target='_blank'
                                   rel='noreferrer'>
                                    <FaGithub className='FooterFa'/>
                                </a>
                            </span>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://x.com/developrsunesis' target='_blank'
                                   rel='noreferrer'>
                                    <FaTwitter className='FooterFa'/>
                                </a>
                            </span>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://soundcloud.com/doakev' target='_blank'
                                   rel='noreferrer'>
                                    <FaSoundcloud className='FooterFa'/>
                                </a>
                            </span>
                            <span className='ms-2 me-2'>
                                <a className='a-no-decor' href='https://www.instagram.com/athenpixels' target='_blank'
                                   rel='noreferrer'>
                                    <FaInstagram className='FooterFa'/>
                                </a>
                            </span>
                        </div>
                        <div className='mt-3 mt-md-4 d-flex w-100 justify-content-center'>
                            <span className='copyright'>
                                &copy; {currentYear()} {' Copyright '}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    }
}

export default Main